import React, { useEffect, useState } from 'react';
import EwalletIcon from '@assets/pictures/svgs/e-wallet-icon-black.svg';
import {
  fetchUserHistory,
  fetchGuestHistory,
  login,
} from '../../store/auth/authActions';
import GenericService from '@apiService/GenericService';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useIsMobile from '../../customHooks/useIsMobile';
const EwalletTransactions = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [ewalletData, setEwalletData] = useState([]);
  const [availableBalance, setAvailableBalance] = useState(0);
  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser } = loginDetails;
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  const fetchEwalletData = async () => {
    let payload = {
      cust_id: loggedInUser?.cust_id,
    };
    try {
      const resp = await GenericService.GetEwalletData(payload);
      const data = resp?.data?.Records;
      setEwalletData(data);
      if (data?.length > 0) {
        let dataIndex = data?.length - 1;
        let customAvailableBal = data[dataIndex]?.current_balance;
        setAvailableBalance(customAvailableBal);
      }
    } catch (error) {
      console.error('Error fetching ewallet records: ', error);
    }
  };
  useEffect(() => {
    if (loggedInUser?.cust_id) {
      fetchEwalletData();
    } else if (!loggedInUser) {
      history.push(`/`);
    }
  }, [dispatch, history, loggedInUser]);
  return (
    <div className="user-settings">
      <div className="content">
        <h3 className="d-flex justify-content-end align-items-center mb-4">
          <img src={EwalletIcon} className="icon_class_ewallet" />
          {availableBalance}&nbsp; {curr_code}
        </h3>
        {ewalletData?.length > 0 && (
          <div className="table-container">
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Transaction Date & Time</th>
                  <th>Amount</th>
                  <th>Booking Code</th>
                  <th>Movie</th>
                  <th>FNB Order Code</th>
                  {/* <th>FNB Items</th> */}
                  <th>Closing Balance</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {ewalletData.map((elem, index) => (
                  <tr key={index}>
                    <td>{elem?.created_at}</td>
                    <td>
                      <span
                        className={`status-badge ${
                          elem?.t_amount >= 0
                            ? 'status-completed'
                            : 'status-incompleted'
                        }`}
                      >
                        {curr_code} &nbsp;{elem?.t_amount}
                      </span>
                    </td>
                    <td>{elem?.sb_booking_code}</td>
                    <td>{elem?.movie_name}</td>
                    <td>{elem?.receipt_code ? elem?.receipt_code : '-'}</td>
                    {/* <td>{elem?.receipt_code}</td> */}
                    <td className="text-right">
                      {' '}
                      {curr_code} &nbsp;{elem?.current_balance}
                    </td>
                    <td>{elem?.remark}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {ewalletData?.length == 0 && (
          <div className="text-center">No Transaction Found</div>
        )}
      </div>
    </div>
  );
};

export default EwalletTransactions;
